//Gallery.js file

import React, { useRef, useState, useEffect } from 'react'

//components
import PhotoGallery from '../../../components/PhotoGallery'

//assets
import socialMediaBgDeco from '../../../assets/images/brands/rigamarole/social-media-bg-deco.png'

import { isElementInViewport } from '../../../components/functions'


export const Gallery = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    //If no instagram set up & no gallery item, disable the section
    if(!data.display_instagram && (slice.items === undefined || slice.items.length === 0)){
        return(<></>)
    }

    return(
        <section
            className={(isInView)? 'social-media-gallery d-flex align-items-center in-view' : 'social-media-gallery d-flex align-items-center'}
            ref={ref}>
            <div className='w-100'>
                <div className='container mb-3'>
                    <h3 className='d-flex align-items-center position-relative'>
                        <span>FOLLOW US ON INSTAGRAM</span>
                        <img src={socialMediaBgDeco} className="zoom-out-img-lg" alt="Rigamarole Illustration" width="14%" />
                    </h3>
                </div>
                {/* Gallery or instagram here */}
                <div className="gallery-wrapper mt-5 pt-5 slide-up">
                    <div className='social-media-gallery-bg fade-in animation-delay-2'>
                    </div>
                    {(data.display_instagram)?
                    <div className='social-media-insta-bg'>
                        {/* <div className="elfsight-app-b2af7727-ed78-48fb-bed4-53003ac05f57"></div> */}
                    </div>
                    :
                    <div className='social-media-gallery-bg fade-in animation-delay-2'>
                        <PhotoGallery data={slice.items} />
                    </div>
                    }
                </div>
                {/* Eof gallery or instagram here */}
            </div>
        </section>
    )
}