import React, {useState, useRef, useEffect} from 'react'
import { Link } from 'gatsby'

import { isElementInViewport } from '../../../components/functions'


export const ProductDetails = ({ slice }) => {
    const data = slice

    return(
        <section className='product-details'>
            {data.primary.products.map((item, idx) => {
                return (
                    <EachProduct key={"product-details-" + idx} item={item} idx={idx} />
                )
            })}
        </section>
    )
}

// EACH WINE
const EachProduct = ({ item, idx }) => {
    const [isMobile, setIsMobile] = useState(false)
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)
    const [displayedRegion, setDisplayedRegion] = useState({
        region: item.items[0].product_region
    })

    //Adjust image quality
    if(item.primary.product_image.url){
        item.primary.product_image.url = item.primary.product_image.url.replace('w=500&h=554', 'w=960&q=100')
    }
    if(item.primary.product_image_mobile.url){
        item.primary.product_image_mobile.url = item.primary.product_image_mobile.url + "&q=100"
    }

    //Set specific id for each product
    var itemName = item.primary.product_name.text
    itemName = itemName.toLowerCase()
    itemName = itemName.replace(' ', '-')

     //choose the screen size
     const handleResize = () => {
        if(window.innerWidth < 992){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    //create an event listener
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)

        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    return (
        <div
            className={(isInView)? "product-details__item in-view overflow-hidden": "product-details__item overflow-hidden"}
            ref={ref}
            data-idx={idx}
            id={itemName}>
            <div 
                className='row product-details__item-content m-0 p-0'
                style={{backgroundColor: `${(item.primary.product_details_background_color)? item.primary.product_details_background_color : '#fff'}`}}
            >
                <div className="col-lg-6 pb-5 py-lg-5 my-0 my-lg-5 product-details__desc-wrapper slide-up">
                    <div
                        className='product-details__desc pt-lg-5 mt-lg-5'
                        style={{color: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`}}
                    >
                        {(item.primary.product_name.text)?
                            <h6 className='section-title text-uppercase'>
                                <span>RIGAMAROLE</span>
                                {item.primary.product_name.text.toUpperCase().replace('RIGAMAROLE', '')}
                            </h6>
                        :null}
                        {/* Product Details content */}
                            {/* Region */}
                            <div 
                                className='product-details__item-region'
                                style={{borderColor: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`}}
                            >
                                {item.items.map((itemDetails, itemDetailsIdx) => {
                                    if(displayedRegion.region === itemDetails.product_region){
                                        return (
                                            <span key={"item-" + itemDetailsIdx} className='active' role="button" tabIndex={itemDetailsIdx}
                                                style={{borderColor: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`}}
                                                onKeyDown={() => setDisplayedRegion({region: itemDetails.product_region})}
                                                onClick={() => setDisplayedRegion({region: itemDetails.product_region})}>
                                                {itemDetails.product_region}
                                            </span>
                                        )
                                    }

                                    return (
                                        <span key={"item-" + itemDetailsIdx} role="button" tabIndex={itemDetailsIdx}
                                            style={{borderColor: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`}}
                                            onKeyDown={() => setDisplayedRegion({region: itemDetails.product_region})}
                                            onClick={() => setDisplayedRegion({region: itemDetails.product_region})}>
                                            {itemDetails.product_region}
                                        </span>
                                    )
                                })}
                            </div>
                            {/* Eof region */}
                            {/* Product details by region */}
                            <div className='product-details__item-by-region'>
                                {item.items.map((itemDetails, itemDetailsIdx) => {
                                    if(displayedRegion.region === itemDetails.product_region){
                                        return (
                                            <EachProductDetails item={itemDetails} 
                                                key={"item-" + itemDetailsIdx} 
                                                contentColor={(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'} />
                                        )
                                    }
                                    return null
                                })}
                            </div>
                            {/* Eof product details by region */}
                        {/* Eof product details content */}

                        {/* Buttons */}
                        {(item.primary.product_pdf_link.url || item.primary.where_to_buy_link.url)?
                        <div className='product_details--btn-wrapper pt-3'>
                            {(item.primary.product_pdf_link.url)? 
                                <a href={item.primary.product_pdf_link.url} target="_blank" rel='noreferrer'>
                                    <button 
                                        className='btn btn-primary-2 mx-5 btn-pdf'
                                        style={{
                                            borderColor: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`,
                                            color: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`
                                        }}
                                    >
                                        Download PDF
                                    </button>
                                </a>
                            : null}
                            {(item.primary.where_to_buy_link.url)?
                                    (item.primary.where_to_buy_link.link_type === "Document" && item.primary.where_to_buy_link.uid)?
                                    <Link to={`../${item.primary.where_to_buy_link.uid}`}>
                                        <button 
                                            className='btn btn-primary-2'
                                            style={{
                                                borderColor: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`,
                                                color: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`
                                            }}
                                        >
                                            Where to buy
                                            </button>
                                    </Link>
                                    :
                                    <a href={item.primary.where_to_buy_link.url} target={(item.primary.where_to_buy_link.target)? item.primary.where_to_buy_link.target : "_self"}>
                                        <button
                                            className='btn btn-primary-2'
                                            style={{
                                                borderColor: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`,
                                                color: `${(item.primary.product_details_content_color)? item.primary.product_details_content_color : '#000'}`
                                            }}
                                        >
                                            Where to buy
                                        </button>
                                    </a>
                                : null}
                        </div>
                        : null}
                        {/* Eof buttons */}
                    </div>
                </div>
                <div className='col-lg-6 product-details__img-wrapper p-0'>
                    <div className="product-details__img d-flex justify-content-center align-items-center">
                        {(isMobile)?
                            (item.primary.product_image_mobile.url || item.primary.product_image.url)?
                                <img src={(item.primary.product_image_mobile.url)? item.primary.product_image_mobile.url : item.primary.product_image.url} height="90%" alt={(item.primary.product_image.alt)? item.primary.product_image.alt : item.primary.product_name.text} /> :
                            null
                            : (item.primary.product_image.url)?
                            <img src={item.primary.product_image.url} height="90%" alt={(item.primary.product_image.alt)? item.primary.product_image.alt : item.primary.product_name.text} /> :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const EachProductDetails = ({ item, contentColor }) => {
    return (
        <div data-region={item.product_region}>
            {/* Tasting note */}
            {(item.tasting_note.text)?
            <div className='each-item-details tasting-note' style={{borderColor: contentColor}}>
                <div className='name'>TASTING NOTE</div>
                <div className='description' dangerouslySetInnerHTML={{ __html: item.tasting_note.html }}/>
            </div>
            : null}
            {/* Blend */}
            {(item.blend.text)?
            <div className='each-item-details blend' style={{borderColor: contentColor}}>
                <div className='name'>BLEND</div>
                <div className='description'>{item.blend.text}</div>
            </div>
            : null}
            {/* What it tastes like */}
            {(item.what_it_tastes_like_dry_sweet || item.what_it_tastes_like_light_bold || item.what_it_tastes_like_soft_acidic)?
            <div className='each-item-details what-it-tastes-like' style={{borderColor: contentColor}}>
                <div className='name'>WHAT IT TASTES LIKE</div>
                <div className='description'>
                    {(item.what_it_tastes_like_light_bold)?
                    <div className='light-bold'>
                        <span>LIGHT</span>
                        <span data-taste={item.what_it_tastes_like_light_bold}>
                            <span className='taste-bar-bg' style={{backgroundColor: contentColor}}></span>
                            <span className='taste-bar' style={{backgroundColor: contentColor}}></span>
                        </span>
                        <span>BOLD</span>
                    </div>
                    : null }
                    {(item.what_it_tastes_like_dry_sweet)?
                    <div className='dry-sweet'>
                        <span>DRY</span>
                        <span data-taste={item.what_it_tastes_like_dry_sweet}>
                            <span className='taste-bar-bg' style={{backgroundColor: contentColor}}></span>
                            <span className='taste-bar' style={{backgroundColor: contentColor}}></span>
                        </span>
                        <span>SWEET</span>
                    </div>
                    : null}
                    {(item.what_it_tastes_like_soft_acidic)?
                    <div className='soft-acidic'>
                        <span>SOFT</span>
                        <span data-taste={item.what_it_tastes_like_soft_acidic}>
                            <span className='taste-bar-bg' style={{backgroundColor: contentColor}}></span>
                            <span className='taste-bar' style={{backgroundColor: contentColor}}></span>
                        </span>
                        <span>ACIDIC</span>
                    </div>
                    : null}
                </div>
            </div>
            : null}
            {/* Appellation */}
            {(item.appellation.text)? 
            <div className='each-item-details appellation' style={{borderColor: contentColor}}>
                <div className='name'>APPELLATION</div>
                <div className='description'>{item.appellation.text}</div>
            </div>
            : null}
            {/* Bottle size */}
            {(item.bottle_size)? 
            <div className='each-item-details bottle-size' style={{borderColor: contentColor}}>
                <div className='name'>BOTTLE SIZE</div>
                <div className='description'>{item.bottle_size + "ml"}</div>
            </div>
            : null}
            {/* Alcohol content */}
            {(item.alcohol_content)?
            <div className='each-item-details alcohol-content' style={{borderColor: contentColor}}>
                <div className='name'>ALCOHOL CONTENT (ABV)</div>
                <div className='description'>{item.alcohol_content + "%"}</div>
            </div>
            : null}
        </div>
    )
}