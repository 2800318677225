//Products.js file

import React, { useRef, useState, useEffect } from 'react'

import { isElementInViewport } from '../../../components/functions'

export const Products = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    const clickDatalayer = (data) => {
        //Data layer - Product details clicked
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }

    return(
        <section
            className={(isInView)? 'products py-5 d-flex justify-content-center align-items-center in-view' : 'products py-5 d-flex justify-content-center align-items-center'}
            ref={ref}>
            <div className='container py-0 py-lg-5 mt-lg-5 position-relative'>
                <h6 className='mb-2 mb-lg-4 d-flex justify-content-center align-items-center text-uppercase'><span>{data.section_title}</span></h6>
                <div className='row p-0 justify-content-center product-wrapper'>
                {
                        data.products.map((product, index) => {
                            var item = product.primary
                            return (
                                <div key={"col-"+ index } className={'col-lg-4 brand-product-item px-3 slide-up animation-delay-' + index}>
                                    <div className='bottle-shot mb-3 d-flex justify-content-center align-items-center'>
                                        {(item.product_list_image)?
                                        <img src={item.product_list_image.url} height="90%" alt={(item.product_list_image.alt)? item.product_list_image.alt : "Rigamarole Wine"} />
                                        : null}
                                    </div>
                                    {(product.items.length > 0)?
                                    <p className='origin'>REGIONS OF ORIGIN: {
                                        product.items.map((item, idx) => {
                                            return (idx === 0)? item.product_region : ", " + item.product_region
                                        })
                                    }</p>:
                                    null}
                                    {(item.product_name.text)?
                                    <h5 className='mt-2 mt-lg-4 text-uppercase'>
                                        <span>RIGAMAROLE</span>
                                        {item.product_name.text.toUpperCase().replace('RIGAMAROLE', '')}
                                    </h5>
                                    : null}
                                    {(item.product_description.text)?
                                    <p className='desc mt-2 mt-lg-3'>{item.product_description.text}</p>
                                    : null}
                                    <button className='btn btn-primary-2' onClick={() => {
                                        //Scroll to specific id for each product
                                        var itemName = item.product_name.text
                                        itemName = itemName.toLowerCase()
                                        itemName = itemName.replace(' ', '-')

                                        document.getElementById(itemName).scrollIntoView()

                                        //Data layer - product details clicked event
                                        let dl = {
                                            'event': 'product_details_clicked',
                                            'brand_name': 'rigamarole',
                                            'product_name': `${item.product_name.text.toLowerCase()}`,
                                            'product_position': `${index + 1}`,
                                            'page_url': '',
                                            'page_location': window.location.href
                                        }
                                        clickDatalayer(dl)
                                    }}>{(item.product_link_text.text)? item.product_link_text.text : "Learn more"}</button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}