import * as React from 'react'
import { useEffect, useState, useRef } from 'react'

//assets

import heroBg from '../../../assets/images/brands/rigamarole/hero.png'
import heroBgMobile from '../../../assets/images/brands/rigamarole/hero-mobile.png'
import heroBgMobileDeco from '../../../assets/images/brands/rigamarole/hero-mobile-deco.png'

import { isElementInViewport } from '../../functions'

const RigamaroleHero = ({ title, description, logoUrl, logoAlt }) => {
    const [isMobile, setIsMobile] = useState(false)
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    //choose the screen size
    const handleResize = () => {
        if(window.innerWidth < 992){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    //create an event listener
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)

        if(isElementInViewport(ref.current)){
            setIsInView(true)
        }
    }, [])

    return(
        <>
            <section
                className={(isInView)? 'brand-pg__hero d-flex justify-content-center align-items-center in-view' : 'brand-pg__hero d-flex justify-content-center align-items-center'}
                ref={ref}>
                <div className='brand-pg__hero-bg' style={{backgroundImage: `url(${(isMobile)? heroBgMobile : heroBg})`}}></div>
                <div className="brand-pg__hero-content text-center d-flex">
                    {/* Logo */}
                    {(logoUrl)? <img src={logoUrl} alt={(logoAlt)? logoAlt : "Rigmarole Logo"} width="60%" className='pb-5 mb-3 slide-up' /> : null}
                    <div className='justify-self-end slide-up animation-delay-1'>
                        {/* Title */}
                        {(title)? <h2 className='pb-3 text-uppercase screen-only'>{title}</h2> : null}
                        {/* Description */}
                        {(description)?
                        <div className='desc screen-only' dangerouslySetInnerHTML={{__html: description}}/>
                        : null}
                    </div>
                </div>
            </section>
            {/* Mobile content */}
            {(isMobile)?
            <div 
                className={(isInView)? 'brand-page__hero-content-bottom d-flex flex-column px-3 in-view' : 'brand-page__hero-content-bottom d-flex flex-column px-3'}
                style={{backgroundImage: `url(${heroBgMobileDeco})`}}
                >
                <div className='slide-up'>
                    {/* Title */}
                    {(title)? <h2 className='pb-5 mb-5 text-uppercase'>{title}</h2> : null}
                    {/* Description */}
                    {(description)?
                        <div className='desc' dangerouslySetInnerHTML={{__html: description}}/>
                    : null}
                </div>
            </div>
            : null}
        </>
    )
}

export default RigamaroleHero